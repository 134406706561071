import React, { useCallback } from 'react';
import { IWizardStep } from '../Wizard/WizardBreadCrumbButton';
import styles from './mobileWizardBreadcrumb.module.scss';
import { buildClassNames } from '@ui/utils/classNameUtils';
import { BreadCrumbStatus } from '@ui/components/WizardV2/WizardBreadCrumbButton/WizardBreadCrumbButton';
import {
    getIsClickable,
    getStatus,
} from '@ui/components/WizardV2/WizardBreadCrumb/WizardBreadCrumb';

export interface IProps {
    steps: IWizardStep[];
    currentStepIndex: number;
    onSelect: (stepId: number) => void;
    getIsClickable: (step: IWizardStep) => boolean;
    currentStageId?: number;
}

const MobileWizardBreadcrumb: React.FC<IProps> = props => {
    const currentStep = props.steps[props.currentStepIndex];

    const getStepContainerClassName = useCallback((status: BreadCrumbStatus, clickable: boolean) => buildClassNames(styles, [
        'step',
        `step--${status ?? BreadCrumbStatus.Default}`,
        clickable && 'step--clickable',
    ]), []);

    return (
        <div className={styles['container']}>
            <h3 className={styles['title']}>{currentStep.title}</h3>
            <div className={styles['steps-container']}>
                {props.steps?.map(step => (
                    <button
                        key={step.id}
                        className={getStepContainerClassName(getStatus(step.type), getIsClickable(step))}
                        onClick={() => props.onSelect(step.id)}
                        disabled={!getIsClickable(step)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MobileWizardBreadcrumb;