import React from 'react';

import styles from './pinCodeInput.module.scss';
import { buildClassNames } from '../../../utils/classNameUtils';

export interface IProps {
    index: number;
    value?: string;
    disabled?: boolean;
    focus?: boolean;
    secure?: boolean;
    current?: boolean;
    status?: 'default'|'success'|'error';
}

const PinCodeSlot: React.FC<IProps> = props => {
    const className = buildClassNames(styles, [
        'slot',
        props.focus && 'slot--focus',
        props.disabled && 'slot--disabled',
        `slot--status-${props.status}`,
    ]);

    const hasCaret = props.current && props.focus && !props.disabled;

    function renderContent() {
        if (props.secure) {
            if (props.value?.length > 0) {
                <div className={styles['slot__secure-dot']} />;
            }

            return null;
        }

        return props.value;
    }

    return (
        <div className={className}>
            {renderContent()}
            {hasCaret &&
                <div className={styles['slot__caret']} />
            }
        </div>
    );
};

export default PinCodeSlot;