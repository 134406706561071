/* eslint-disable @next/next/no-img-element */
import React, { useCallback } from 'react';
import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';
import styles from './ExternalAuth.module.scss';
import {
    ExternalAuthVariant,
    IExternalAuthVariantApiModel,
} from '@app/models/common/oauth';

export interface IProps {
    /**
     * Данные о сервисе внешней авторизации.
     */
    variant: IExternalAuthVariantApiModel;
    /**
     * Добавочный префикс к всплывающей подсказке при наведении на кнопку.
     * Объединяется с названием сервиса.
     */
    tooltipPrefix?: string;
    /**
     * Событие, срабатывает, когда пользователь нажал на кнопку.
     * @event
     */
    onClick?: (id: ExternalAuthVariant) => void;
}

/**
 * Компонент кнопки внешней авторизации.
 */
export default function ExternalAuthButton({
    variant,
    tooltipPrefix = 'Войти с помощью сервиса',
    onClick,
}: IProps) {
    const didButtonClicked = useCallback(() => onClick && onClick(variant?.id), [ variant?.id, onClick ]);

    return (
        <Tooltip key={variant.id} disableWrapper title={`${tooltipPrefix} ${variant.title}`}>
            <button className={styles['button']} type="button" onClick={didButtonClicked} data-test-id={`external-auth-button--${variant.id}`}>
                <img
                    className={styles['image']}
                    src={variant.large_image_url ?? variant.logo_url}
                    alt={variant.title}
                    height={28}
                />
            </button>
        </Tooltip>
    );
}