import React, { useCallback } from 'react';
import Tooltip from '../../../ui/TooltipIcon/Tooltip';
import { buildClassNames } from '../../../utils/classNameUtils';

import styles from './wizardBreadCrumbButton.module.scss';

export enum BreadCrumbStatus {
    Default = 'default',
    Active = 'active',
    Completed = 'completed',
}

export interface IProps {
    id: number;
    order?: number;
    title: string;
    hint?: React.ReactNode|JSX.Element;
    status?: BreadCrumbStatus;
    selected?: boolean;

    clickable?: boolean;
    onClick?: (id: number) => void;
}

const WizardBreadCrumbButton: React.FC<IProps> = props => {
    const status = props.status || BreadCrumbStatus.Default;

    const didClicked = useCallback(() => props.onClick && props.onClick(props.id), [ props.id, props.onClick ]);

    const containerClassName = buildClassNames(styles, [
        'container',
        `container--${status}`,
        props.clickable && 'container--clickable',
    ]);

    const innerContent = (
        <>
            <div className={styles['title']}>
                <Tooltip title={props.hint}>
                    {typeof props.order === 'number' ? props.order : props.id}. {props.title}
                </Tooltip>
            </div>
            <div className={styles['bottom']}>
                <div className={styles['line']} />
                <div className={styles['dot']} />
            </div>
        </>
    );

    if (props.clickable) {
        return (
            <button className={containerClassName} onClick={didClicked}>
                {innerContent}
            </button>
        );
    }

    return (
        <div className={containerClassName}>
            {innerContent}
        </div>
    );
};

export default WizardBreadCrumbButton;
