import React, {
    useCallback,
    useEffect,
} from 'react';
import {
    ISmsCodeApproveState,
    SmsConfirmFormValues,
} from '@frontend/jetlend-core/src/models/sms';
import { IFormProps } from '@frontend/jetlend-core/src/models/form';
import { useTickTimerV2 } from '@frontend/jetlend-core/src/hooks/useTickTimer';
import { formatPhoneNumber } from '@frontend/jetlend-core/src/formatters/formatUtils';
import iconRetry from '@frontend/jetlend-assets/icons/icon-retry.svg';
import { PinCodeInputField } from '@ui/ui/inputs/PinCodeInput/PinCodeInput';
import { mergeClassNames } from '@ui/utils/classNameUtils';
import { FormAlert } from '../../ui/forms/FormAlert/FormAlert';
import Button from '../../ui/inputs/Button/Button';
import styles from './smsCodeApproveContent.module.scss';

export interface ISharedProps {
  allowRetry?: boolean;
  className?: string;
}

export interface IProps extends ISharedProps {
  smsState: ISmsCodeApproveState;
  onSubmit: (values: any) => void;
  onClose: () => void;
  onRetry: () => void;
  hideCancelButton?: boolean;
  submitText?: string;
}

type Props = IProps & IFormProps<SmsConfirmFormValues>;

const SmsCodeApproveContent: React.FC<Props> = props => {
    const {
        pristine,
        invalid,
        onSubmit,
        onClose,
        handleSubmit,
        formValues,
        allowRetry,
        hideCancelButton,
        submitText = 'Продолжить',
        smsState,
        onRetry,
        className,
    } = props;
    const {
        isOpen,
        error,
        confirmationContent,
        loading,
    } = smsState || {};

    const [remainingSecondsBeforeRetry, resetRetryTimeout] = useTickTimerV2(
        60,
        allowRetry === true,
        [smsState?.smsId]
    );

    const didRetryClicked = useCallback(() => {
        onRetry && onRetry();
        resetRetryTimeout();
    }, [resetRetryTimeout]);

    const didConfirmClicked = useCallback(() => {
        if (invalid) {
            return;
        }

        onSubmit && onSubmit(formValues);
    }, [invalid, onSubmit, formValues]);

    useEffect(() => {
        if (isOpen) {
            if (formValues?.code?.length === 4 || formValues?.code?.length === 8) {
                didConfirmClicked();
            }
        }
    }, [ formValues, isOpen, invalid, onSubmit ]);

    return (
        <div
            className={mergeClassNames([styles['container'], className])}
            data-test-id="sms-approve"
        >
            {confirmationContent && (
                <div
                    className={styles['sms-dialog__confirmation-content']}
                    data-test-id="sms-approve__confirmation-content"
                >
                    {confirmationContent}
                </div>
            )}

            {smsState?.phone && (
                <p className={styles['phone-text']}>
                    Код подтверждения отправлен вам на номер
                    <br />
                    <span className={styles['phone']} data-test-id="sms-approve__phone">
                        {formatPhoneNumber(smsState.phone)}
                    </span>
                </p>
            )}

            <div
                className={styles['code-container']}
                data-test-id="sms-approve__code"
            >
                <PinCodeInputField
                    name="code"
                    size={4}
                    oneTimeCode
                    autoFocus
                    disabled={loading}
                    status={error ? 'error' : undefined}
                />
            </div>

            {allowRetry && (
                <div className={styles['buttons']}>
                    <Button
                        type="plain"
                        icon={iconRetry}
                        text={
                            remainingSecondsBeforeRetry > 0
                                ? `Отправить код повторно через ${remainingSecondsBeforeRetry} сек.`
                                : 'Выслать код повторно'
                        }
                        onClick={didRetryClicked}
                        disabled={loading || remainingSecondsBeforeRetry > 0}
                        testId="sms-approve__resend"
                    />
                </div>
            )}

            <FormAlert alert={error} />

            <div className={styles['buttons']}>
                {!hideCancelButton &&
                    <Button
                        type="secondary"
                        text="Отмена"
                        size="small"
                        disabled={loading}
                        onClick={onClose}
                    />
                }
                <Button
                    buttonType="submit"
                    disabled={pristine || invalid}
                    loading={loading}
                    text={submitText}
                    size={hideCancelButton ? 'big' : 'small'}
                    onClick={handleSubmit}
                    width={hideCancelButton && 350}
                />
            </div>
        </div>
    );
};

export default SmsCodeApproveContent;
