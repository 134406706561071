import {
    DependencyList,
    useEffect,
} from 'react';

/**
 * Хук, реализующий обертку над {@link setInterval}.
 *
 * При размонтировании компонента, интервал будет очищен.
 *
 * @param fn Событие, которое сработает, когда интервал завершится.
 * @param periodInMs Период срабатывания интервала в миллисекундах
 * @param deps Зависимости, которые заставят интервал пересоздаться.
 * @category Hooks
 *
 * @see {@link useTickTimerV2}
 * @see {@link useRemainingSeconds}
 * @see {@link useTimeout}
 * @see {@link useOptionalTimeout}
 * @see {@link useOptionalInterval}
 */
export function useInterval(fn: () => void, periodInMs: number, deps?: DependencyList) {
    useEffect(() => {
        const intervalId = setInterval(fn, periodInMs);

        return () => {
            clearInterval(intervalId);
        };
    }, [ periodInMs, ...(deps || []) ]);
}

/**
 * Хук, реализующий обертку над {@link setInterval} с опциональным флагом.
 * Интервал будет работать, только в том, случае если флаг установлен в true.
 *
 * При размонтировании компонента, интервал будет очищен.
 *
 * @param fn Событие, которое сработает, когда интервал завершится.
 * @param flag Флаг, при котором интервал будет запускаться или отключаться.
 * @param periodInMs Период срабатывания интервала в миллисекундах
 * @param deps Зависимости, которые заставят интервал пересоздаться.
 * @category Hooks
 *
 * @see {@link useTickTimerV2}
 * @see {@link useRemainingSeconds}
 * @see {@link useTimeout}
 * @see {@link useOptionalTimeout}
 * @see {@link useInterval}
 */
export function useOptionalInterval(fn: () => void, flag: boolean, periodInMs: number, deps: DependencyList = []) {
    useEffect(() => {
        if (!flag) {
            return;
        }

        const intervalId = setInterval(fn, periodInMs);

        return () => {
            clearInterval(intervalId);
        };
    }, [ periodInMs, flag, ...deps ]);
}