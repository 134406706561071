import React, { useCallback } from 'react';
import { buildClassNames } from '../../../utils/classNameUtils';

import styles from './wizardBreadCrumbButton.module.scss';

export enum BreadCrumbStatus {
  Default = 'default',
  Active = 'active',
  Completed = 'completed',
}

export interface IProps {
  id: number;
  order?: number;
  title: string;
  hint?: React.ReactNode | JSX.Element;
  status?: BreadCrumbStatus;
  selected?: boolean;
  maxOrder?: number;

  clickable?: boolean;
  onClick?: (id: number) => void;
}

const WizardBreadCrumbLightButton: React.FC<IProps> = props => {
    const { status = BreadCrumbStatus.Default } = props;

    const didClicked = useCallback(
        () => props.onClick && props.onClick(props.id),
        [props.id, props.onClick]
    );

    const containerClassName = buildClassNames(styles, [
        'container',
        `container--${status}`,
        props.clickable && 'container--clickable',
        'container--light',
    ]);

    const innerContent = (
        <div className={styles['bottom']}>
            <div className={styles['dot']} />
            {props.order <= props.maxOrder && <div className={styles['line']} />}
        </div>
    );

    if (props.clickable) {
        return (
            <button className={containerClassName} onClick={didClicked}>
                {innerContent}
            </button>
        );
    }

    return <div className={containerClassName}>{innerContent}</div>;
};

export default WizardBreadCrumbLightButton;
