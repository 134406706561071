import React, {
    useCallback,
    useState,
} from 'react';
import { buildRange } from '@frontend/jetlend-core/src/utils/arrayUtils';
import { IConnectableInputProps } from '@frontend/jetlend-core/src/ui/inputs/common';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import PinCodeSlot from './PinCodeSlot';
import styles from './pinCodeInput.module.scss';

export interface IProps extends IConnectableInputProps {
    size: number;
    disabled?: boolean;
    status?: 'default'|'success'|'error';
    secure?: boolean;
    oneTimeCode?: boolean;
    autoFocus?: boolean;
}

const PinCodeInput: React.FC<IProps> = props => {
    const [ focus, setFocus ] = useState(false);

    const didBlurred = useCallback(e => {
        setFocus(false);
        props.onBlur && props.onBlur(e);
    }, [ props.onBlur ]);

    const didFocused = useCallback(e => {
        setFocus(true);
        props.onFocus && props.onFocus(e);
    }, [ props.onFocus ]);

    const didKeyPressed = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        // Allow to Paste the code with Ctrl+V
        if (e.ctrlKey && e.code.toLowerCase() === 'keyv') {
            return;
        }

        if (!/^(digit|numpad)[0-9]|backspace|enter$/i.test(e.code)) {
            e.preventDefault();
            return;
        }

        if (/[0-9]/i.test(e.code)) {
            if (e.currentTarget.value.length === props.size) {
                e.preventDefault();

            }
        }
    }, [ props.size ]);

    const range = buildRange(0, props.size - 1);

    const value = (props.value as string || '').split('');
    const getSlotValue = (index: number) => index < value.length
        ? value[index]
        : '';

    function isCurrentSlot(index: number) {
        return index === Math.max(0, Math.min(value.length, props.size - 1));
    }

    const hasError = props.error && props.error.length > 0;
    const status = hasError
        ? 'error'
        : props.status || 'default';

    return (
        <div>
            <div className={styles['input-wrapper']}>
                <div className={styles['slots']}>
                    {range.map(idx =>
                        <PinCodeSlot
                            key={idx}
                            index={idx}
                            disabled={props.disabled}
                            value={getSlotValue(idx)}
                            current={isCurrentSlot(idx)}
                            secure={props.secure}
                            focus={focus}
                            status={status}
                        />
                    )}
                </div>
                <input
                    name={props.name}
                    autoFocus={props.autoFocus}
                    type={props.secure ? 'password' : 'text'}
                    className={styles['input']}
                    disabled={props.disabled}
                    autoComplete={props.oneTimeCode ? 'one-time-code' : 'off'}
                    value={props.value}
                    onKeyDown={didKeyPressed}
                    onChange={props.onChange}
                    onFocus={didFocused}
                    onBlur={didBlurred}
                />
            </div>
            {hasError &&
                <div className={styles['label__error']}>
                    {props.error}
                </div>
            }
        </div>
    );
};

export const PinCodeInputField = connectToField(PinCodeInput);

export default PinCodeInput;