import React, {
    useMemo,
    useRef,
} from 'react';
import ScrollBars from 'react-scrollbars-custom';
import { useWindowResizeEffect } from '@ui/hooks/useWindowResizeEffect';
import {
    IWizardStep,
    WizardStepType,
} from '../../Wizard/WizardBreadCrumbButton';
import WizardBreadCrumbButton, { BreadCrumbStatus } from '../WizardBreadCrumbButton/WizardBreadCrumbButton';
import {
    isMobile,
    useWindowSize,
} from '../../../utils/responsive';
import MobileWizardBreadcrumb from '../../MobileWizardBreadcrumb/MobileWizardBreadcrumb';
import { mergeClassNames } from '../../../utils/classNameUtils';
import WizardBreadCrumbLightButton from '../WizardBreadCrumbButton/WizardBreadCrumbLightButton';

import styles from './wizardBreadCrumb.module.scss';

export interface IProps {
    steps: IWizardStep[];
    onSelect: (stepId: number) => void;
    light?: boolean;

    mobileProgressBar?: boolean;
    currentStageId?: number;
}

export function getStatus(oldStatus?: WizardStepType): BreadCrumbStatus {
    switch (oldStatus) {
    case WizardStepType.Active:
        return BreadCrumbStatus.Active;
    case WizardStepType.Completed:
        return BreadCrumbStatus.Completed;
    case WizardStepType.None:
        return BreadCrumbStatus.Default;
        // TODO Refactor it later
    case WizardStepType.Current:
        return BreadCrumbStatus.Active;
    default:
        return BreadCrumbStatus.Default;
    }
}

export function getIsClickable(step: IWizardStep): boolean {
    if (step.alwaysClickable) {
        return true;
    }

    return step.type === WizardStepType.Active || (step.type === WizardStepType.Completed && step.allowCompleted);
}

const WizardBreadCrumb: React.FC<IProps> = props => {
    const currentStepIndex = props.steps.findIndex(step => step.type === WizardStepType.Current);
    const containerRef = useRef<HTMLDivElement>();

    const { width: windowWidth } = useWindowSize();

    useWindowResizeEffect(() => {
        const containerElement = containerRef?.current;
        if (!containerElement || currentStepIndex < 0) {
            return;
        }

        const stepElement = containerElement.querySelector<HTMLDivElement>(`[data-js-step-id="step-${currentStepIndex}"]`);
        if (!stepElement) {
            return;
        }

        containerElement.scrollTo({
            behavior: 'smooth',
            left: stepElement.offsetLeft - containerElement.offsetLeft - 25,
        });
    }, [ containerRef?.current, currentStepIndex ]);

    const mobileScrollbarOffset = useMemo(() => {
        const itemWidth = 215;

        const dotWidth = 12;

        const margin = 9;

        return (itemWidth + dotWidth + margin) * currentStepIndex;
    }, [currentStepIndex]);

    if (props.light) {
        return (
            <div className={styles['breadcrumbs']}>
                {props.steps.map((step, index, array) => (
                    <WizardBreadCrumbLightButton
                        key={step.id}
                        maxOrder={array.length-1}
                        id={step.id}
                        order={index + 1}
                        title={step.title}
                        hint={step.hint}
                        status={getStatus(step.type)}
                        clickable={getIsClickable(step)}
                        onClick={props.onSelect}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className={styles['container']}>
            <ScrollBars
                scrollLeft={mobileScrollbarOffset}
                mobileNative
                noScrollY
                className={styles['scroll-container']}
                trackXProps={{
                    renderer: props => {
                        const {
                            elementRef,
                            ...restProps
                        } = props;
                        return <div {...restProps} ref={elementRef} className={styles['scroll-track']} />;
                    },
                }}
                thumbXProps={{
                    renderer: props => {
                        const {
                            elementRef,
                            ...restProps
                        } = props;
                        return <div {...restProps} ref={elementRef} className={styles['scroll-thumb']} />;
                    },
                }}
            >
                {(isMobile(windowWidth) && props.mobileProgressBar)
                    ? (
                        <div className={mergeClassNames([styles['breadcrumbs'], styles['breadcrumbs-mobile']])}>
                            <MobileWizardBreadcrumb
                                steps={props.steps}
                                currentStepIndex={currentStepIndex}
                                onSelect={props.onSelect}
                                currentStageId={props.currentStageId}
                                getIsClickable={getIsClickable}
                            />
                        </div>
                    )
                    : (
                        <div ref={containerRef} className={styles['breadcrumbs']}>
                            {props.steps.map((step, index) => (
                                <WizardBreadCrumbButton
                                    key={step.id}
                                    id={step.id}
                                    order={index + 1}
                                    title={step.title}
                                    hint={step.hint}
                                    status={getStatus(step.type)}
                                    clickable={getIsClickable(step)}
                                    onClick={props.onSelect}
                                />
                            ))}
                        </div>
                    )}
            </ScrollBars>
        </div>
    );
};

export default React.memo(WizardBreadCrumb);