'use client';

import { useCallback } from 'react';
import WizardBreadCrumb from '@frontend/jetlend-web-ui/src/components/WizardV2/WizardBreadCrumb/WizardBreadCrumb';
import { IWizardStep } from '@frontend/jetlend-web-ui/src/components/Wizard/WizardBreadCrumbButton';
import FormBackLink from '@app/components/server/ui/FormBackLink/FormBackLink';

function onSelectCrumb(id: number) {

}

export interface IAuthWizardHeaderProps {
    /**
     * Список всех шагов процесса авторизации.
     */
    steps?: IWizardStep[];

    /**
     * Возможно ли вернуться назад.
     */
    back?: boolean;
    /**
     * Ссылка для возвращения назад.
     */
    backHref?: string;
    /**
     * Событие, которое срабатывает при нажатии на кнопку "Назад".
     * @event
     */
    onBackClick?: () => void;
}

/**
 * Компонент отвечает за шапку визарда авторизации
 */
export default function AuthWizardHeader({
    back,
    steps,
    onBackClick,
    backHref: href,
}: IAuthWizardHeaderProps) {
    const didBackButtonClicked = useCallback(() => onBackClick && onBackClick(), [onBackClick]);

    return (
        <div className="d-flex d-flex--v-center h-mb-10">
            {back &&
                <FormBackLink href={href} onClick={didBackButtonClicked} />
            }
            {steps && steps.length > 0 &&
                <WizardBreadCrumb steps={steps} light onSelect={onSelectCrumb} />
            }
        </div>
    );
}
