import { useSelector } from 'react-redux';
import {
    useCallback,
    useEffect,
} from 'react';
import {
    ICollectionApiHandler,
    networkStateHandler,
} from '../ducks/api';
import {
    IBaseApiHookOptions,
    isFetchRequiredByOptions,
} from './common';
import { useActions } from './useActions';

type HookReturnType<TApiModel> = [
    TApiModel|null|undefined,
    boolean,
    () => void
];

/**
 * Хук для работы с {@link collectionApiHandler} внутри компонентов.
 *
 * @param handler Хендлер для работы с АПИ коллекций, реализующий интерфейс {@link ICollectionApiHandler}
 * @param source Идентификатор для выборки данных.
 * @param options Дополнительные параметры, определяющие поведение загрузки данных.
 * @returns Возвращает данные, флаг загрузки, функцию для принудительного обновления.
 * @category Hooks
 *
 * @see {@link useSimpleApiHandler}
 * @see {@link useListApiHandler}
 * @see {@link useIsApiFetching}
 *
 * @example
 *  const [
 *      // Данные из АПИ, могут быть null или undefined, пока АПИ не вернет корректный ответ
 *      data,
 *      // Флаг, отвечающий за загрузку данных
 *      isFetching,
 *      // Метод для принудительного обновления данных
 *      fetchUpdate
 *  ] = useCollectionApiHandler(reportRowsHandler, dashboardId);
 *
 *  // Загружаем данные, только когда модальное окно будет открыто
 *  const [ data ] = useCollectionApiHandler(exchangeOverviewHandler, loanId, {
 *      fetchWhen: isOpen,
 *  });
 */
export default function useCollectionApiHandler<TSource, TApiModel>(
    handler: ICollectionApiHandler<TSource, TApiModel>,
    source: TSource,
    options?: IBaseApiHookOptions
): HookReturnType<TApiModel> {
    // We are using network state to refetch data when global cache cleared
    const networkState = useSelector(networkStateHandler.selector);

    const data = useSelector(handler.itemSelector(source));
    const isFetching = useSelector(handler.isItemFetching(source));

    // Use JSON serialization to prevent re-fetching on re-renders for Object sources.
    const sourceDependency = typeof source === 'string' || typeof source === 'number'
        ? source
        : JSON.stringify(source);

    const [ fetch, fetchUpdateWithKey ] = useActions([
        handler.fetch,
        handler.fetchUpdate,
    ]);

    const fetchUpdate = useCallback(
        () => fetchUpdateWithKey(source),
        [ fetchUpdateWithKey, sourceDependency ]
    );

    const fetchRequired = isFetchRequiredByOptions(options);
    const isDataCleared = typeof data === 'undefined';

    useEffect(() => {
        if (fetchRequired) {
            fetch(source);
        }
    }, [ sourceDependency, fetch, networkState.cacheVersion, isDataCleared, fetchRequired ]);

    return [
        data,
        isFetching,
        fetchUpdate,
    ];
}
